import { useDebouncedValue } from '/machinery/useDebouncedValue'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

export function trackInteraction({ category, type, action, additionalValues = {} }) {
  pushToDataLayer({
    event: 'interaction',
    metadata: {
      event: {
        interaction: {
          category,
          type,
          action,
          ...additionalValues,
        }
      }
    }
  })
}

export function trackFilterInteractionMultiValue({ name, value, inputType, selected }) {
  trackFilterInteraction({
    name,
    value,
    inputType,
    categorySpecificValues: {
      filter_selected: selected,
    }
  })
}

export function trackFilterInteraction({ name, value, inputType, categorySpecificValues = {} }) {
  pushToDataLayer({
    event: 'filtered',
    metadata: {
      event: {
        filter: {
          filter_name: name,
          filter_value: value,
          filter_category: inputType,
          ...categorySpecificValues,
        }
      }
    }
  })
}

export function useTrackCurrentFiltersAndQuery(filtersAndValues, totalcount, filterCategory, query = undefined) {
  const debouncedFiltersAndValues = useDebouncedValue(filtersAndValues)
  const debouncedTotalCount = useDebouncedValue(totalcount)

  React.useEffect(
    () => {
      pushToDataLayer({
        metadata: {
          ...(query && { query }),
          filters: Object.values(debouncedFiltersAndValues)
            .flatMap(({ id, options, value }) => {
              if (id === 'salary_scale') return options.map(x => ({
                filter_name: 'salary_scale',
                filter_value: x.id,
                filter_category: 'range_slider',
                filter_selected: x.id >= value.min && x.id <= value.max
              }))

              return options?.map(x => ({
                filter_name: id,
                filter_value: x.id,
                filter_category: filterCategory,
                ...(Array.isArray(value) && { filter_selected: value.includes(x.id) }),
              }))
            }
            ),
          filters_totalcount: debouncedTotalCount
        }
      })
    },
    [debouncedFiltersAndValues, debouncedTotalCount, filterCategory, query]
  )
}
