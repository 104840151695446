export function useDebouncedValue(value, config = { interval: 300 }) {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, config.interval)

      return () => {
        clearTimeout(handler)
      }
    },
    [value, config.interval]
  )

  return debouncedValue
}
